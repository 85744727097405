// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  /* border: 1px solid #707070; */
  position: relative;
}
input[type='checkbox']:checked + label::after {
  color: #4338f7;
  content: '✔';
  font-size: 25px;
  width: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/AIModelGenerator/style/xai.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,+BAA+B;EAC/B,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,OAAO;EACP,MAAM;AACR","sourcesContent":["input[type='checkbox'] {\n  display: none;\n}\n\ninput[type='checkbox'] + label {\n  display: inline-block;\n  width: 30px;\n  height: 30px;\n  /* border: 1px solid #707070; */\n  position: relative;\n}\ninput[type='checkbox']:checked + label::after {\n  color: #4338f7;\n  content: '✔';\n  font-size: 25px;\n  width: 30px;\n  height: 30px;\n  text-align: center;\n  position: absolute;\n  left: 0;\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

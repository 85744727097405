import { Button } from 'antd'

const Faq = () => {
  return (
    <div className="flex justify-center items-center ">
      <a target="_blank" href="https://developing-midnight-f3f.notion.site/3-EEJI-10db2dbce6ba42259f533b5f692ec92f">
        <Button type="text" className="text-white">
          FAQ
        </Button>
      </a>
    </div>
  )
}

export default Faq

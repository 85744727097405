// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1200px) {
  .row-column-title {
    display: none !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/AIModelGenerator/style/data-analysis-style.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["@media (max-width: 1200px) {\n  .row-column-title {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import styled from '@emotion/styled'
import { App } from 'antd'
import IconServerUpload from 'assets/img/icons/XAI/upload_b_io.png'
import IconCustomerUpload from 'assets/img/icons/XAI/upload_b_user.png'
import useModal from 'hooks/useModal'
import { useTranslation } from 'react-i18next'
import CardButton from './components/button/CardButton'

const UploadPage = () => {
  const { t } = useTranslation()
  const { message } = App.useApp()
  const { openModal, closeModal } = useModal()

  const buttonItems = [
    {
      title: 'EEJI',
      description: t('Retrieve the model data generated by the AI Model Generator.'),
      icon: IconServerUpload,
      handleClick: () => handleClick('SavedModelImport'),
      buttonName: t('Select Model'),
    },
    {
      title: 'USER',
      description: t('Retrieve the model data stored on the user’s PC.'),
      icon: IconCustomerUpload,
      handleClick: () => handleClick('UserModelImport'),
      buttonName: t('Upload Model'),
    },
  ]

  function handleClick(type: string) {
    openModal({
      modalTitle: t('Model Import'),
      modalType: type,
      modalProps: {
        onClick: () => {
          closeModal()
        },
      },
    })
  }

  return (
    <>
      <UploadContainer>
        <div style={{ width: '100%', textAlign: 'center', marginTop: 32, flexDirection: 'row' }}>
          <IconContainer>
            {buttonItems.map((item: any, index: number) => (
              <CardButton item={item} key={index} />
            ))}
          </IconContainer>
        </div>
        <div
          style={{
            display: 'block',
            textAlign: 'center',
            marginTop: '100px',
          }}
        ></div>
      </UploadContainer>
    </>
  )
}

export default UploadPage

const UploadContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`
